// Shadows
// @each $shadow, $value in $shadows {
//   .shadow-#{$shadow} {
//     box-shadow: $value !important;
//   }
// }

// @each $shadow, $value in $shadows-soft {
//   .shadow-#{$shadow}-soft {
//     box-shadow: $value !important;
//   }
// }

// @each $shadow, $value in $shadows-strong {
//   .shadow-#{$shadow}-strong {
//     box-shadow: $value !important;
//   }
// }

// Functions

// Dynamically change text color based on background color

@function set-notification-text-color($color) {
  @if (lightness($color) > 70) {
    @return $gray-800;
  } @else {
    @return $white;
  }
}

@function color-level($color: $primary, $level: 0) {
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);

  @return mix($color-base, $color, $level);
}

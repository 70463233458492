$primary: #2e294e;
$secondary: #eadeda;
$info: #f5f5f5;
@import 'src/mdb/scss/mdb.pro';
@import '/mdb/plugins/css/all.min.css';



p a, li a {
	text-decoration: underline;
}

.hidden {
	display: none;
}

.form-thanks {
	display: none;
}

.blockquote {
	margin-left: ($spacer * 1.2) !important;
	margin-right: ($spacer * 1.2) !important;
	font-style: italic;
	font-size: 90%;
	}

.blockquote footer {
	text-align: right;
	font-size: 100%;
}

div.ais-hits::before {
	content: "Search Results";
	font-size: 24px;
}


div.ais-search-box--powered-by {
    position: absolute;
    top: 50%;
    right: 15%;
    color: #999;
}

div.ais-search-box {
	margin-bottom: 0;
}

div.ais-hits {
	padding-top: 2px;
	margin-top: 6px;
	margin-bottom: 2px;
}

.ais-hits--item {
	font-size: 14px;
	line-height: 1.5;
}

.card-expandable {
	.card-body {
		overflow: hidden;
		transition-duration: 0.3s;
	}

	&.card-offerings .card-body {
		height: 13rem;
	}

	&.card-news .card-body {
		height: 12rem;
	}

	&.card-blog .card-body {
		height: 9rem;
	}


	.card-expand-btn  {
		transition: 0.3s ease-in-out;
		background-color: white;
	}

	.card-expand-btn:hover  {
		background-color: #f5f5f5;
		box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.15), 0 2px 4px -1px rgba(0, 0, 0, 0.10) !important
	}
	
	.card-expand-btn:active  {
		background-color: #f4f4f4;
		box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.25), 0 2px 4px -1px rgba(0, 0, 0, 0.17) !important
	}

	.card-expand-btn i {
		transition-duration: 0.2s;
		transform: translateY(1px);
	}

	.card-expand-btn i.active {
		transform: rotate(180deg);
	}

	.card-body.card-shadow:after{
			content: "";
			transition-duration: 0.2s;
			position: absolute;  bottom: 0; left: -15px; right: -15px;
			box-shadow: inset rgba(255, 255, 255, 0.849) 0 -22px 22px;
			height: 100%;
			overflow-y: visible;
			pointer-events: none;
	}

	.card-body.active:after{
		box-shadow: none;
	}
	.card-body p:last-child {
		margin-bottom: 0 !important;
	}
}

@include media-breakpoint-between(xs, xl) {
	.xs-lg-text-center {
		text-align: center;
		}
	.xs-lg-card-img-container {
		height: 8rem;
	}
	.xs-lg-card-img-container img {
		max-height: 100%;
	}
}

@include media-breakpoint-up(lg){
  .xs-lg-card-img-container {
    min-height: 120px;
		max-height: 60%;
	}
	.xs-lg-card-img-container img {
		max-height: 100%;
	}
}

@include media-breakpoint-up(xl){
  .filter-dropdown-section {
    transform: translateY(-30px);
  }
}

.select-option:first-child {
	display: none;
}

.select-input {
	min-width: 250px;
}


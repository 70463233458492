@import './components/core-theme';
@import './components/colors-theme';
@import './components/type-theme';
@import './components/datepicker-theme';
@import './components/alert-theme';
@import './components/badge-theme';
@import './components/button-theme';
@import './components/list-group-theme';
@import './components/card-theme';
@import './components/modal-theme';
@import './components/dropdown-theme';
@import './components/breadcrumb-theme';
@import './components/nav-theme';
@import './components/navbar-theme';
@import './components/pagination-theme';
@import './components/popover-theme';
@import './components/progress-theme';
@import './components/ripple-theme';
@import './components/scrollspy-theme';
@import './components/shadows-theme';
@import './components/tables-theme';
@import './components/toasts-theme';
@import './components/tooltips-theme';
@import './components/form-check-theme';
@import './components/form-control-theme';
@import './components/form-range-theme';
@import './components/form-file-theme';
@import './components/form-select-theme';
@import './components/input-group-theme';
@import './components/autocomplete-theme';
@import './components/datatables-theme';
@import './components/timepicker-theme';
@import './components/loading-theme';
@import './components/sidenav-theme';
@import './components/stepper-theme';
@import './components/accordion-theme';
@import './components/popconfirm-theme';

@mixin mdb-theme($theme) {
  @include mdb-core-theme($theme);
  @include mdb-colors-theme($theme);
  @include mdb-type-theme($theme);
  @include mdb-datepicker-theme($theme);
  @include mdb-alert-theme($theme);
  @include mdb-button-theme($theme);
  @include mdb-list-group-theme($theme);
  @include mdb-card-theme($theme);
  @include mdb-modal-theme($theme);
  @include mdb-dropdown-theme($theme);
  @include mdb-breadcrumb-theme($theme);
  @include mdb-nav-theme($theme);
  @include mdb-navbar-theme($theme);
  @include mdb-pagination-theme($theme);
  @include mdb-popover-theme($theme);
  @include mdb-progress-theme($theme);
  @include mdb-ripple-theme($theme);
  @include mdb-scrollspy-theme($theme);
  @include mdb-shadows-theme($theme);
  @include mdb-tables-theme($theme);
  @include mdb-toasts-theme($theme);
  @include mdb-tooltips-theme($theme);
  @include mdb-form-check-theme($theme);
  @include mdb-form-control-theme($theme);
  @include mdb-form-range-theme($theme);
  @include mdb-form-file-theme($theme);
  @include mdb-form-select-theme($theme);
  @include mdb-input-group-theme($theme);
  @include mdb-autocomplete-theme($theme);
  @include mdb-datatables-theme($theme);
  @include mdb-timepicker-theme($theme);
  @include mdb-loading-theme($theme);
  @include mdb-sidenav-theme($theme);
  @include mdb-stepper-theme($theme);
  @include mdb-accordion-theme($theme);
  @include mdb-popconfirm-theme($theme);
}

// Variables pro

// scss-docs-start zindex-stack pro
$zindex-sidenav: 1035 !default;
$zindex-sidenav-backdrop: 1034 !default;
$zindex-timepicker: 1065 !default;
$zindex-datepicker: 1065 !default;
$zindex-datepicker-container: 1066 !default;
$zindex-lightbox: 1100 !default;
$zindex-lightbox-toolbar: 1110 !default;
$zindex-popconfirm: 1080 !default;
$zindex-popconfirm-backdrop: 1070 !default;
$zindex-autocomplete-dropdown-container: 1065 !default;
// scss-docs-end zindex-stack pro

// Sidenav

$sidenav-transform: translateX(-100%) !default;
$sidenav-background-color: $white !default;
$sidenav-width: 240px !default;
$sidenav-height: 100vh !default;
$sidenav-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05) !default;

$sidenav-data-hidden-false-transform: translateX(0%) !default;
$sidenav-data-color-light-color: rgba(255, 255, 255, 0.6) !default;
$sidenav-data-right-true-transform: translateX(100%) !default;
$sidenav-data-slim-collapsed-true-width: 70px !default;

$sidenav-menu-padding: 0.2rem !default;

$sidenav-collapse-sidenav-link-font-size: 0.78rem !default;
$sidenav-collapse-sidenav-link-height: 1.5rem !default;
$sidenav-collapse-sidenav-link-padding-left: 3rem !default;

$sidenav-link-font-size: 0.89rem !default !default;
$sidenav-link-padding-y: 1rem !default;
$sidenav-link-padding-x: 1.5rem !default;
$sidenav-link-height: 3rem !default;
$sidenav-link-border-radius: 5px !default;
$sidenav-link-transition: all 0.3s linear !default;

$sidenav-link-hover-color: $primary !default;
$sidenav-link-hover-background-color: #f6f9fc !default;

$sidenav-link-active-focus-color: $sidenav-link-hover-color !default;
$sidenav-link-active-focus-background-color: $sidenav-link-hover-background-color !default;
$sidenav-link-active-color: $sidenav-link-hover-color !default;

$sidenav-rotate-icon-margin-right: 0.8rem !default;
$sidenav-rotate-icon-transition: transform 0.3s !default;

$sidenav-backdrop-background-color: rgba(0, 0, 0, 0.1) !default;

$sidenav-light-color: $sidenav-data-color-light-color !default;

// Rating

$rating-icon-padding: 0.25rem !default;

// Timepicker

// $timepicker-wrapper-z-index: 1071 !default;
$timepicker-wrapper-background-color: rgba(0, 0, 0, 0.4) !default;

$timepicker-wrapper-inline-border-radius: 0.5rem !default;

$timepicker-elements-min-width: 310px !default;
$timepicker-elements-min-height: 325px !default;
$timepicker-elements-background: $white !default;
$timepicker-elements-border-top-right-radius: 0.6rem !default;
$timepicker-elements-border-top-left-radius: 0.6rem !default;
$timepicker-elements-media-border-bottom-left-radius: 0.5rem !default;

$timepicker-head-background-color: $blue-accent-400 !default;
$timepicker-head-height: 100px !default;
$timepicker-head-border-top-right-radius: 0.5rem !default;
$timepicker-head-border-top-left-radius: 0.5rem !default;
$timepicker-head-padding-y: 10px !default;
$timepicker-head-padding-right: 24px !default;
$timepicker-head-padding-left: 50px !default;

$timepicker-head-media-padding: 10px !default;
$timepicker-head-media-padding-right: 10px !default;
$timepicker-head-media-min-height: 305px !default;

$timepicker-button-font-size: 0.8rem !default;
$timepicker-button-min-width: 64px !default;
$timepicker-button-font-weight: 500 !default;
$timepicker-button-line-height: 40px !default;
$timepicker-button-border-radius: 10px !default;
$timepicker-button-letter-spacing: 0.1rem !default;
$timepicker-button-color: $blue-accent-400 !default;
$timepicker-button-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !default;
$timepicker-button-hover-background-color: rgba(0, 0, 0, 0.08) !default;
$timepicker-button-focus-background-color: $timepicker-button-hover-background-color !default;
$timepicker-button-padding-x: 10px !default;
$timepicker-button-height: 40px !default;
$timepicker-button-margin-bottom: 10px !default;

$timepicker-submit-inline-height: 48px !default;
$timepicker-submit-inline-min-width: 48px !default;
$timepicker-submit-inline-margin-left: 30px !default;
$timepicker-submit-inline-color: $white !default;

$timepicker-current-font-size: 3.75rem !default;
$timepicker-current-font-weight: 300 !default;
$timepicker-current-line-height: 1.2 !default;
$timepicker-current-letter-spacing: -0.00833em !default;
$timepicker-current-color: $white !default;
$timepicker-current-opacity: 0.54 !default;

$timepicker-mode-wrapper-font-size: 18px !default;
$timepicker-mode-wrapper-color: rgba(255, 255, 255, 0.54) !default;

$timepicker-clock-wrapper-min-width: 310px !default;
$timepicker-clock-wrapper-max-width: 325px !default;
$timepicker-clock-wrapper-min-height: 305px !default;

$timepicker-clock-width: 260px !default;
$timepicker-clock-height: 260px !default;
$timepicker-clock-background-color: rgba(0, 0, 0, 0.07) !default;

$timepicker-clock-inner-top: 50% !default;
$timepicker-clock-inner-left: 50% !default;
$timepicker-clock-inner-transform: translate(-50%, -50%) !default;
$timepicker-clock-inner-width: 160px !default;
$timepicker-clock-inner-height: 160px !default;

$timepicker-time-tips-inner-width: 32px !default;
$timepicker-time-tips-inner-height: 32px !default;
$timepicker-time-tips-inner-font-size: 1.1rem !default;
$timepicker-time-tips-inner-font-weight: 300 !default;
$timepicker-time-tips-inner-active-color: $white !default;
$timepicker-time-tips-inner-active-background-color: $blue-accent-400 !default;
$timepicker-time-tips-inner-active-font-weight: 400 !default;
$timepicker-time-tips-inner-disabled-color: #b3afaf !default;

$timepicker-dot-font-weight: 300 !default;
$timepicker-dot-line-height: 1.2 !default;
$timepicker-dot-letter-spacing: -0.00833em !default;
$timepicker-dot-color: $white !default;
$timepicker-dot-font-size: 3.75rem !default;
$timepicker-dot-opacity: 0.54 !default;
$timepicker-dot-media-font-size: 3rem !default;
$timepicker-dot-media-font-weight: 400 !default;

$timepicker-current-inline-font-size: 2.5rem !default;

$timepicker-item-width: 20px !default;
$timepicker-item-height: 20px !default;
$timepicker-item-padding: 10px !default;
$timepicker-item-font-size: 1.1em !default;

$timepicker-item-middle-dot-top: 50% !default;
$timepicker-item-middle-dot-left: 50% !default;
$timepicker-item-middle-dot-width: 6px !default;
$timepicker-item-middle-dot-height: $timepicker-item-middle-dot-width !default;
$timepicker-item-middle-dot-transform: translate(-50%, -50%) !default;
$timepicker-item-middle-dot-border-radius: 50% !default;
$timepicker-item-middle-dot-background-color: $blue-accent-400 !default;

$timepicker-hand-pointer-background-color: $blue-accent-400 !default;
$timepicker-hand-pointer-bottom: 50% !default;
$timepicker-hand-pointer-height: 40% !default;
$timepicker-hand-pointer-left: calc(50% - 1px) !default;
$timepicker-hand-pointer-width: 2px !default;

$timepicker-transform-transition: transform 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  height 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !default;

$timepicker-time-tips-width: 32px !default;
$timepicker-time-tips-height: 32px !default;
$timepicker-time-tips-font-size: 1.1rem !default;
$timepicker-time-tips-active-color: $white !default;

$timepicker-circle-top: -21px !default;
$timepicker-circle-left: -15px !default;
$timepicker-circle-width: 4px !default;
$timepicker-circle-border-width: 14px !default;
$timepicker-circle-border-color: $blue-accent-400 !default;
$timepicker-circle-height: 4px !default;

$timepicker-hour-mode-color: $white !default;
$timepicker-hour-mode-opacity: 0.54 !default;
$timepicker-hour-mode-hover-background-color: rgba(0, 0, 0, 0.15) !default;
$timepicker-hour-mode-active-color: $white !default;
$timepicker-hour-mode-font-size: 2.5rem !default;

$timepicker-footer-border-bottom-left-radius: 0.5rem !default;
$timepicker-footer-border-bottom-right-radius: 0.5rem !default;
$timepicker-footer-height: 56px !default;
$timepicker-footer-padding-x: 12px !default;
$timepicker-footer-background-color: $white !default;

$timepicker-container-max-height: calc(100% - 64px) !default;

$timepicker-icon-up-down-transition: 0.2s ease all !default;
$timepicker-icon-up-down-transform: translate(-50%, -50%) !default;
$timepicker-icon-up-down-left: 50% !default;
$timepicker-icon-up-down-width: 30px !default;
$timepicker-icon-up-down-height: 30px !default;
$timepicker-icon-up-top: -35px !default;
$timepicker-icon-down-bottom: -47px !default;

$timepicker-icon-btn-width: 30px !default;
$timepicker-icon-btn-height: 30px !default;
$timepicker-input-color: $blue-accent-400 !default;

$timepicker-btn-icon-right: -10px !default;
$timepicker-btn-icon-transform: translate(-50%, -50%) !default;
$timepicker-btn-icon-top: 50% !default;
$timepicker-btn-icon-transition: all 0.3s ease !default;
$timepicker-btn-icon-hover-color: $blue-accent-400 !default;
$timepicker-btn-icon-focus-color: $blue-accent-400 !default;

// Lightbox

$lightbox-gallery-background-color: rgba(0, 0, 0, 0.9) !default;
$lightbox-gallery-transition: all 0.3s ease-out !default;

$lightbox-gallery-toolbar-height: 50px !default;
$lightbox-gallery-toolbar-transition: opacity 0.4s !default;

$lightbox-gallery-counter-color: #b3b3b3 !default;
$lightbox-gallery-counter-padding-x: 10px !default;

$lightbox-gallery-toolbar-button-width: 50px !default;
$lightbox-gallery-toolbar-button-height: 50px !default;
$lightbox-gallery-toolbar-button-color: #b3b3b3 !default;
$lightbox-gallery-toolbar-button-transition: color 0.2s !default;
$lightbox-gallery-toolbar-button-hover-color: $white !default;
$lightbox-gallery-toolbar-button-before-font-weight: 900 !default;

$lightbox-gallery-content-top: 50px !default;
$lightbox-gallery-content-left: $lightbox-gallery-content-top !default;
$lightbox-gallery-content-width: calc(100% - 100px) !default;
$lightbox-gallery-content-height: $lightbox-gallery-content-width !default;

$lightbox-gallery-image-transform: scale(0.25) !default;
$lightbox-gallery-image-transition: all 0.4s ease-out !default;

$lightbox-gallery-arrow-right-width: 50px !default;
$lightbox-gallery-arrow-right-transition: opacity 0.4s !default;
$lightbox-gallery-arrow-right-button-width: 50px !default;
$lightbox-gallery-arrow-right-button-height: 50px !default;
$lightbox-gallery-arrow-right-button-color: #b3b3b3 !default;
$lightbox-gallery-arrow-right-button-transition: color 0.2s !default;
$lightbox-gallery-arrow-right-button-hover-color: $white !default;
$lightbox-gallery-arrow-right-button-before-font-weight: 900 !default;

$lightbox-gallery-arrow-left-width: 50px !default;
$lightbox-gallery-arrow-left-transition: opacity 0.4s !default;
$lightbox-gallery-arrow-left-button-width: 50px !default;
$lightbox-gallery-arrow-left-button-height: 50px !default;
$lightbox-gallery-arrow-left-button-color: #b3b3b3 !default;
$lightbox-gallery-arrow-left-button-transition: color 0.2s !default;
$lightbox-gallery-arrow-left-button-hover-color: $white !default;
$lightbox-gallery-arrow-left-button-before-font-weight: 900 !default;

$lightbox-gallery-caption-wrapper-height: 50px !default;
$lightbox-gallery-caption-color: $white !default;
$lightbox-gallery-caption-margin-x: 10px !default;

$lightbox-gallery-loader-transition: opacity 1s !default;

$lightbox-gallery-button-focus-color: $white !default;
$lightbox-disabled-scroll-media-padding-right: 17px !default;

// Datepicker

$datepicker-toggle-right: -10px !default;
$datepicker-toggle-top: 50% !default;
$datepicker-toggle-focus-color: $blue-accent-400 !default;

$datepicker-backdrop-background-color: rgba(0, 0, 0, 0.4) !default;

$datepicker-dropdown-container-width: 328px !default;
$datepicker-dropdown-container-height: 380px !default;
$datepicker-dropdown-container-background-color: $white !default;
$datepicker-dropdown-container-border-radius: 0.5rem !default;
$datepicker-dropdown-container-box-shadow: $box-shadow-4 !default;

$datepicker-modal-container-transform: translate(-50%, -50%) !default;
$datepicker-modal-container-width: 328px !default;
$datepicker-modal-container-height: 512px !default;
$datepicker-modal-container-background-color: $white !default;
$datepicker-modal-container-border-radius: 0.6rem 0.6rem 0.5rem 0.5rem !default;
$datepicker-modal-container-box-shadow: $datepicker-dropdown-container-box-shadow !default;
// $datepicker-modal-container-z-index: 1072 !default;
$datepicker-modal-container-date-media-margin-top: 100px !default;
$datepicker-modal-container-day-cell-media-width: 32x !default;
$datepicker-modal-container-day-cell-media-height: $datepicker-modal-container-day-cell-media-width !default;
$datepicker-modal-container-media-width: 475px !default;
$datepicker-modal-container-media-height: 360px !default;
$datepicker-modal-container-media-day-cell-width: 36px !default;
$datepicker-modal-container-media-day-cell-height: $datepicker-modal-container-media-day-cell-width !default;

$datepicker-header-height: 120px !default;
$datepicker-header-padding-x: 24px !default;
$datepicker-header-background-color: $blue-accent-400 !default;
$datepicker-header-border-radius: 0.5rem 0.5rem 0 0 !default;

$datepicker-title-height: 32px !default;
$datepicker-title-text-font-size: 10px !default;
$datepicker-title-text-font-weight: 400 !default;
$datepicker-title-text-letter-spacing: 1.7px !default;
$datepicker-title-text-color: $white !default;

$datepicker-date-height: 72px !default;
$datepicker-date-text-font-size: 34px !default;
$datepicker-date-text-font-weight: 400 !default;
$datepicker-date-text-color: $white !default;

$datepicker-date-controls-padding-top: 10px !default;
$datepicker-date-controls-padding-x: 12px !default;

$datepicker-arrow-border-width: 5px !default;
$datepicker-arrow-margin-left: 5px !default;
$datepicker-arrow-transform: rotate(180deg) !default;

$datepicker-date-controls-color: rgba(0, 0, 0, 0.64) !default;

$datepicker-view-change-button-padding: 10px !default;
$datepicker-view-change-button-color: #666 !default;
$datepicker-view-change-button-font-weight: 500 !default;
$datepicker-view-change-button-font-size: 0.9rem !default;
$datepicker-view-change-button-border-radius: 10px !default;
$datepicker-view-change-button-state-background-color: #eee !default;
$datepicker-view-change-button-after-border-width: 5px !default;
$datepicker-view-change-button-after-margin-left: 5px !default;

$datepicker-arrow-controls-margin-top: 10px !default;

$datepicker-previous-button-width: 40px !default;
$datepicker-previous-button-height: $datepicker-previous-button-width !default;
$datepicker-previous-button-line-height: $datepicker-previous-button-height !default;
$datepicker-previous-button-color: rgba(0, 0, 0, 0.64) !default;
$datepicker-previous-button-margin-right: 24px !default;
$datepicker-previous-button-state-background-color: #eee !default;
$datepicker-previous-button-state-border-radius: 50% !default;

$datepicker-previous-button-after-margin: 15.5px !default;
$datepicker-previous-button-after-border-width: 2px !default;
$datepicker-previous-button-after-transform: translateX(2px) rotate(-45deg) !default;

$datepicker-next-button-width: 40px !default;
$datepicker-next-button-height: $datepicker-next-button-width !default;
$datepicker-next-button-line-height: $datepicker-next-button-height !default;
$datepicker-next-button-color: rgba(0, 0, 0, 0.64) !default;
$datepicker-next-button-margin-background-color: #eee !default;
$datepicker-next-button-state-border-radius: 50% !default;

$datepicker-next-button-after-margin: 15.5px !default;
$datepicker-next-button-after-border-width: 2px !default;
$datepicker-next-button-after-transform: translateX(-2px) rotate(45deg) !default;

$datepicker-view-padding-x: 12px !default;
$datepicker-table-width: 304px !default;

$datepicker-day-heading-width: 40px !default;
$datepicker-day-heading-height: $datepicker-day-heading-width !default;
$datepicker-day-heading-font-size: 12px !default;
$datepicker-day-heading-font-weight: 400 !default;

$datepicker-cell-disabled-color: #ccc !default;
$datepicker-cell-hover-background-color: #d3d3d3 !default;
$datepicker-cell-selected-background-color: $blue-accent-400 !default;
$datepicker-cell-selected-color: $white !default;
$datepicker-cell-focused-background-color: #eee !default;
$datepicker-cell-focused-selected-background-color: $blue-accent-400 !default;
$datepicker-cell-border-width: 1px !default;
$datepicker-cell-border-color: $black !default;

$datepicker-small-cell-width: 40px !default;
$datepicker-small-cell-height: $datepicker-small-cell-width !default;

$datepicker-small-cell-content-width: 36px !default;
$datepicker-small-cell-content-height: $datepicker-small-cell-content-width !default;
$datepicker-small-cell-content-line-height: $datepicker-small-cell-content-height !default;
$datepicker-small-cell-content-border-radius: 50% !default;
$datepicker-small-cell-content-font-size: 13px !default;

$datepicker-large-cell-width: 76px !default;
$datepicker-large-cell-height: 42px !default;

$datepicker-large-cell-content-width: 72px !default;
$datepicker-large-cell-content-height: 40px !default;
$datepicker-large-cell-content-line-height: $datepicker-large-cell-content-height !default;
$datepicker-large-cell-content-padding-y: 1px !default;
$datepicker-large-cell-content-padding-x: 2px !default;
$datepicker-large-cell-content-border-radius: 999px !default;

$datepicker-yearview-content-padding-x: $datepicker-view-padding-x !default;

$datepicker-footer-height: 56px !default;
$datepicker-footer-padding-x: $datepicker-yearview-content-padding-x !default;

$datepicker-footer-btn-background-color: $white !default;
$datepicker-footer-btn-color: $blue-accent-400 !default;
$datepicker-footer-btn-padding-x: 10px !default;
$datepicker-footer-btn-font-size: 0.8rem !default;
$datepicker-footer-btn-font-weight: 500 !default;
$datepicker-footer-btn-height: 40px !default;
$datepicker-footer-btn-line-height: $datepicker-footer-btn-height !default;
$datepicker-footer-btn-letter-spacing: 0.1rem !default;
$datepicker-footer-btn-border-radius: 10px !default;
$datepicker-footer-btn-margin-bottom: 10px !default;
$datepicker-footer-btn-state-background-color: #eee !default;

// Popconfirm

$popconfirm-padding: 1rem !default;
$popconfirm-background-color: $white !default;
$popconfirm-border-radius: 0.5rem !default;

$popconfirm-popover-width: 300px !default;
$popconfirm-popover-margin: 5px !default;
$popconfirm-modal-width: $popconfirm-popover-width !default;
$popconfirm-buttons-container-btn-ml: 0.75rem !default;
$popconfirm-backdrop-background-color: rgba(0, 0, 0, 0.4) !default;

// Datatable

$datatable-font-size: 0.9rem !default;
$datatable-background-color: $white !default;
$datatable-table-th-td-max-width: 250px !default;
$datatable-table-th-td-padding-y: 1rem !default;
$datatable-table-th-td-padding-x: 1.4rem !default;
$datatable-thead-tr-border-width: 1px !default;
$datatable-thead-th-font-weight: 500 !default;
$datatable-thead-fixed-cell-background-color: $white !default;

$datatable-tbody-font-weight: 300 !default;
$datatable-tbody-tr-transition: all 0.3s ease-in !default;
$datatable-tbody-tr-last-child-height: 71px !default;
$datatable-tbody-loader-height: 2px !default;

$datatable-tbody-progress-animation: datatableProgress 3s ease-in-out !default;
$datatable-tbody-progress-width: 45% !default;
$datatable-tbody-progress-opacity: 0.5 !default;
$datatable-tbody-progress-border-radius: 1px !default;

$datatable-pagination-padding-y: 0.5rem !default;
$datatable-pagination-border-width: 1px !default;
$datatable-pagination-nav-font-size: 0.9rem !default;
$datatable-pagination-buttons-margin-left: 2rem !default;
$datatable-pagination-button-padding-x: 1rem !default;

$datatable-sort-icon-transition-duration: 0.3s !default;
$datatable-sort-icon-left: 0.4rem !default;
$datatable-sort-icon-top: calc(50% - 0.5rem) !default;

$datatable-select-wrapper-font-size: 0.9rem !default;
$datatable-select-wrapper-font-weight: 300 !default;

$datatable-select-font-size: 1rem !default;
$datatable-select-line-height: 1.3 !default;
$datatable-select-padding-top: 0.4em !default;
$datatable-select-padding-right: 1.2em !default;
$datatable-select-padding-bottom: 0.3em !default;
$datatable-select-padding-left: 0.6em !default;
$datatable-select-margin-right: 1.4rem !default;
$datatable-select-margin-left: 0.1rem !default;
$datatable-select-background: url("data:image/svg+xml !default;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23000'><polygon points='0,0 100,0 50,50'/></svg>") !default;
$datatable-select-background-position: right 0.4em top 60%, 0 0 !default;
$datatable-select-background-size: 0.65em auto, 100% !default;
$datatable-select-focus-font-weight: 500 !default;
$datatable-select-option-color: $black !default;

$datatable-sm-th-td-padding-y: 0.5rem !default;
$datatable-sm-th-td-padding-x: 1.4rem !default;
$datatable-sm-tbody-tr-last-child-height: 55px !default;
$datatable-sm-pagination-padding: 0.2rem !default;

$datatable-dark-check-border-color: $white !default;
$datatable-dark-select-background: url("data:image/svg+xml !default;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='white'><polygon points='0,0 100,0 50,50'/></svg>") !default;
$datatable-dark-datatable-progress-opacity: 0.8 !default;

$datatable-hover-tbody-tr-transition: background-color 0.2s ease-in !default;
$datatable-bordered-th-td-border-width: 1px !default;
$datatable-loading-select-background: url("data:image/svg+xml !default;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='grey'><polygon points='0,0 100,0 50,50'/></svg>") !default;

// Perfect scrollbar

$perfectScrollbar-ps-ps__rail-x-y-bg-color: #eee !default;
$perfectScrollbar-ps-ps__rail-x-y-opacity: 0.6 !default;
$perfectScrollbar-ps-ps__rail-x-y-hover-opacity: 0.9 !default;
$perfectScrollbar-ps-ps__rail-x-y-transition-opacity-bg: background-color 0.2s linear,
  opacity 0.2s linear !default;
$perfectScrollbar-ps-ps__rail-x-transition-height-bg: background-color 0.2s linear,
  height 0.2s ease-in-out !default;
$perfectScrollbar-ps-ps__rail-y-transition-width-bg: background-color 0.2s linear,
  width 0.2s ease-in-out !default;

$perfectScrollbar-ps-z-index: 1035 !default;
$perfectScrollbar-ps-ps__rail-x-y-clicking: #999 !default;
$perfectScrollbar-ps-ps__rail-x-y-clicking-length: 0.6875rem !default;
$perfectScrollbar-ps-ps__rail-x-y-length: 0.9375rem !default;
$perfectScrollbar-ps-ps__thumb-x-y-length: 0.375rem !default;
$perfectScrollbar-ps-ps__thumb-x-y-border-radius: $perfectScrollbar-ps-ps__thumb-x-y-length !default;
$perfectScrollbar-ps-ps__thumb-x-y-position-length: 0.125rem !default;
$perfectScrollbar-ps-ps__thumb-x-y-color: #aaa !default;

// Chips
$chip-height: 32px !default;
$chip-font-size: 13px !default;
$chip-font-weight: 500 !default;
$chip-font-color: rgba($black, 0.6) !default;
$chip-line-height: $chip-height !default;
$chip-padding-right: 12px !default;
$chip-br: 16px !default;
$chip-background-color: #e0e0e0 !default;
$chip-margin-bottom: 1rem !default;
$chip-margin-right: 1rem !default;
$chip-img-margin-right: 8px !default;
$chip-img-margin-left: -12px !default;
$chip-img-height: $chip-height !default;
$chip-img-width: $chip-height !default;
$chip-close-font-size: 16px !default;
$chip-close-line-height: $chip-height !default;
$chip-close-padding-left: 8px !default;

$chips-margin-bottom: 30px !default;
$chips-min-height: 45px !default;
$chips-padding-bottom: 1rem !default;
$chips-input-font-color: rgba($black, 0.6) !default;
$chips-input-font-size: 13px !default;
$chips-input-font-weight: 500 !default;
$chips-input-height: $chip-height !default;
$chips-input-margin-right: 20px !default;
$chips-input-line-height: $chip-height !default;
$chips-input-width: 120px !default;

// Select
$form-outline-select-arrow-color: #000 !default;
$form-outline-select-arrow-font-size: 0.8rem !default;
$form-outline-select-arrow-top: 9px !default;
$form-outline-select-arrow-right: 9px !default;

$form-outline-select-valid-color: #00b74a !default;
$form-outline-select-invalid-color: #f93154 !default;

$form-outline-select-clear-btn-color: #000 !default;
$form-outline-select-clear-btn-font-size: 1rem !default;
$form-outline-select-clear-btn-top: 7px !default;
$form-outline-select-clear-btn-right: 27px !default;
$form-outline-select-clear-btn-focus-color: $primary !default;

$form-outline-select-sm-clear-btn-font-size: 0.8rem !default;
$form-outline-select-sm-clear-btn-top: 4px !default;

$form-outline-select-lg-clear-btn-top: 11px !default;

$form-outline-select-dropdown-container-z-index: $zindex-popconfirm-backdrop !default;

$form-outline-select-dropdown-bgc: #fff !default;
$form-outline-select-dropdown-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
  0 2px 10px 0 rgba(0, 0, 0, 0.12) !default;
$form-outline-select-dropdown-min-width: 100px !default;
$form-outline-select-dropdown-transform: scaleY(0.8) !default;
$form-outline-select-dropdown-transition: all 0.2s !default;

$form-outline-select-dropdown-open-transform: scaleY(1) !default;

$form-outline-select-dropdown-input-group-padding: 10px !default;

$form-outline-select-label-max-width: 80% !default;
$form-outline-select-label-active-transform: translateY(-1rem) translateY(0.1rem) scale(0.8) !default;

$form-outline-select-lg-label-active-transform: translateY(-1.25rem) translateY(0.1rem) scale(0.8) !default;
$form-outline-select-sm-label-active-transform: translateY(-0.83rem) translateY(0.1rem) scale(0.8) !default;

$form-outline-select-input-focused-color: #616161 !default;

$form-outline-select-label-color: $primary !default;

$form-outline-select-notch-border-width: 2px !default;
$form-outline-select-notch-border-color: $primary !default;
$form-outline-select-notch-transition: all 0.2s linear !default;

$form-outline-select-input-focused-arrow-color: $primary !default;

$form-outline-select-white-focus-arrow-color: #fff !default;
$form-outline-select-white-arrow-color: #fff !default;
$form-outline-select-white-clear-btn: #fff !default;

$form-outline-select-sm-arrow-top: 3px !default;
$form-outline-select-lg-arrow-top: 13px !default;

$form-outline-select-options-wrapper-scrollbar-width: 4px !default;
$form-outline-select-options-wrapper-scrollbar-height: 4px !default;
$form-outline-select-options-wrapper-scrollbar-border-bottom-right-radius: 4px !default;
$form-outline-select-options-wrapper-scrollbar-border-bottom-left-radius: 4px !default;
$form-outline-select-options-wrapper-scrollbar-thumb-height: 50px !default;
$form-outline-select-options-wrapper-scrollbar-thumb-bgc: #999 !default;
$form-outline-select-options-wrapper-scrollbar-thumb-border-radius: 4px !default;

$form-outline-select-option-group-label-pl: 16px !default;
$form-outline-select-option-group-label-pr: 16px !default;
$form-outline-select-option-group-label-font-size: 1rem !default;
$form-outline-select-option-group-label-font-weight: 400 !default;
$form-outline-select-option-group-label-color: rgba(0, 0, 0, 0.54) !default;

$form-outline-select-option-group-select-option-pl: 26px !default;

$form-outline-select-option-color: rgba(0, 0, 0, 0.87) !default;
$form-outline-select-option-pl: 16px !default;
$form-outline-select-option-pr: 16px !default;
$form-outline-select-option-font-size: 1rem !default;
$form-outline-select-option-font-weight: 400 !default;

$form-outline-select-option-hover-not-disabled-bgc: #ddd !default;
$form-outline-select-option-active-bgc: #ddd !default;
$form-outline-select-option-selected-active-bgc: #ddd !default;
$form-outline-select-option-selected-disabled-color: #9e9e9e !default;
$form-outline-select-option-selected-bgc: #eee !default;
$form-outline-select-option-disabled-color: #9e9e9e !default;

$form-outline-select-option-text-form-check-input-mr: 10px !default;

$form-outline-select-option-secondary-text-font-size: 0.8rem !default;
$form-outline-select-option-secondary-text-color: #6c757d !default;

$form-outline-select-option-icon-width: 28px !default;
$form-outline-select-option-icon-height: 28px !default;

$form-outline-select-custom-content-padding: 16px !default;

$form-outline-select-no-results-pl: 16px !default;
$form-outline-select-no-results-pr: 16px !default;

$form-outline-select-white-arrow: #fff !default;
